import {inject,bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import {PLATFORM} from 'aurelia-pal';
import {LoginService} from './shared/services/loginservice';
import {SharedState} from './shared/state/sharedstate';
import { AuthorizeStep } from './authentication/auth-config';

@inject(Router,LoginService,SharedState)
export class App {

    @bindable login; // Set to true if the user is on the login page

    constructor(router,loginservice,sharedState) {
        this.router = router;
        this.loginservice =  loginservice;
        this.sharedState = sharedState;
        this.login= true;
    }

    configureRouter(config, router) {
        config.title = 'KensiumHR';
        config.options.pushState = false;
        config.addAuthorizeStep(AuthorizeStep);
        config.map([
            {route: ['', 'login'], moduleId:  PLATFORM.moduleName('components/login/logincomponent'), name: 'login',  title: 'Login'},
            {route: ['forgotpassword'], moduleId: PLATFORM.moduleName('components/forgotpassword/forgotpasswordcomponent'), name: 'forgotpassword',  title: 'ForgotPassword'},
            {route: ['home'], moduleId: PLATFORM.moduleName('components/home/homecomponent'), name: 'home',  title: 'Home', settings: { auth: true }},
            {route: ['dashboard'], moduleId: PLATFORM.moduleName('components/dashboard/dashboardcomponent'), name: 'dashboard',  title: 'Dashboard', settings: { auth: true }},
            {route: ['employeemaster'], moduleId: PLATFORM.moduleName('components/employee/employeemastercomponent'), name: 'employeemaster',  title: 'EmployeeMaster', settings: { auth: true }},
            {route: ['myprofile'], moduleId: PLATFORM.moduleName('components/myprofile/myprofilecomponent'), name: 'myprofile',  title: 'My Profile', settings: { auth: true }},
            {route: ['recording'], moduleId: PLATFORM.moduleName('components/recordingtime/recordingtime'), name: 'recording',  title: 'Attendance', settings: { auth: true }},
            {route: ['changepassword'], moduleId: PLATFORM.moduleName('components/changepassword/changepasswordcomponent'), name: 'changepassword',  title: 'Change Password', settings: { auth: true }},
            {route: ['support'], moduleId: PLATFORM.moduleName('components/support/support'), name: 'support',  title: 'support', settings: { auth: true }},
            {route: ['feedbackform'], moduleId: PLATFORM.moduleName('components/feedbackform/feedbackcomponent'), name: 'feedback', title: 'feedbackform'},
            {route: ['organization/myfeedback'], moduleId: PLATFORM.moduleName('components/employeefeedback/employeefeedbackcomponent'), name: 'myfeedback', title: 'employeefeedback', settings: { auth: true }},
            {route: ['appreciation'], moduleId: PLATFORM.moduleName('components/appreciation/appreciationcomponent'), name: 'appreciation',  title: 'appreciation', settings: { auth: true }},
            {route: ['task'], moduleId: PLATFORM.moduleName('components/task/taskcomponent'), name: 'task',  title: 'Task', settings: { auth: true }},
            {route: ['myappreciation'], moduleId: PLATFORM.moduleName('components/appreciation/myappreciationcomponent'), name: 'myappreciation',  title: 'myappreciation', settings: { auth: true }},
            {route: ['datatable'], moduleId: PLATFORM.moduleName('components/datatable/datatablecomponent'), name: 'datatable',  title: 'DataTable', settings: { auth: true }},
            {route: ['sample'], moduleId: PLATFORM.moduleName('components/sample/samplecomponent'), name: 'sample', title: 'Sample', settings: { auth: true }},
            {route: ['appreciationsprovidedbyme'], moduleId: PLATFORM.moduleName('components/appreciation/appreciationsprovidedbymecomponent'), name: 'appreciationsprovidedbyme',  title: 'Appreciations Provided By Me', settings: { auth: true }},
            {route: ['topperformancepreview'], moduleId: PLATFORM.moduleName('components/appreciation/topperformancepreviewcomponent'), name: 'topperformancepreview',  title: 'Top Performance Preview', settings: { auth: true }},
            {route: ['uploadimagepopup'], moduleId: PLATFORM.moduleName('components/uploadimagepopup/uploadimagepopupcomponent'), name: 'uploadimagepopup',  title: 'UploadImagePopUp', settings: { auth: true }},
            {route: ['employeemaster'], moduleId: PLATFORM.moduleName('components/employeemaster/employeemastercomponent'), name: 'EmployeeMaster',  title: 'EmployeeMaster', settings: { auth: true }},
            {route: ['employeedetails/:id'], moduleId: PLATFORM.moduleName('components/myprofile/myprofilecomponent'), name: 'employee',  title: 'Employee Details', settings: { auth: true }},
            {route: ['applytimeoff'], moduleId: PLATFORM.moduleName('components/leavemanagement/applytimeoff/applytimeoffcomponent'), name: 'applytimeoff', title: 'Apply Time Off', settings: { auth: true }},
            {route: ['mytimeoffdetails'], moduleId: PLATFORM.moduleName('components/leavemanagement/mytimeoffdetails/mytimeoffdetailscomponent'), name: 'mytimeoffdetails',  title: 'My Time Off Request', settings: { auth: true }},
            {route: ['mytimeoffsummary'], moduleId: PLATFORM.moduleName('components/leavemanagement/mytimeoffsummary/mytimeoffsummarycomponent'), name: 'mytimeoffsummary',  title: 'My Time Off Summary', settings: { auth: true }},
            {route: ['employeetimeoffdetails'], moduleId: PLATFORM.moduleName('components/leavemanagement/employeetimeoffdetails/employeetimeoffdetailscomponent'), name: 'employeetimeoffdetails', title: 'Employee Time Off Details', settings: { auth: true }},
            {route: ['assigntimeoff'], moduleId: PLATFORM.moduleName('components/leavemanagement/assigntimeoff/assigntimeoffcomponent'), name: 'assigntimeoff',  title: 'Assign Time Off', settings: { auth: true }},
            {route: ['instantapproval'], moduleId: PLATFORM.moduleName('components/instantapproval/instantapprovalcomponent'), name: 'instantapproval',  title: 'Instant Approval', settings: { auth: true }},
            {route: ['employeetimeoffsummary'], moduleId: PLATFORM.moduleName('components/leavemanagement/employeetimeoffsummary/employeetimeoffsummarycomponent'), name: 'employeetimeoffsummary',  title: 'Employee Time Off Summary', settings: { auth: true }},
            {route: ['myattendance'], moduleId: PLATFORM.moduleName('components/attendancetracking/myattendance/myattendance'), name: 'myattendance',  title: 'My Attendance', settings: { auth: true }},
            {route: ['attendancesummaryadmin'], moduleId: PLATFORM.moduleName('components/attendancetracking/adminattendancesummary/adminattendancesummary'), name: 'attendancesummaryadmin',  title: 'Attendance Summary Admin', settings: { auth: true }},
            {route: ['attendancedetailsadmin'], moduleId: PLATFORM.moduleName('components/attendancetracking/adminattendancedetails/adminattendancedetails'), name: 'attendancedetailsadmin',  title: 'Attendance Details Admin', settings: { auth: true }},
            {route: ['holidaylist'], moduleId: PLATFORM.moduleName('components/leavemanagement/holidaylist/holidaylistcomponent'), name: 'holidaylist',  title: 'Holiday List', settings: { auth: true }},
            {route: ['organization'], moduleId: PLATFORM.moduleName('components/organization/organizationcomponent'), name: 'organization',  title: 'Organization', settings: { auth: true }},
            {route: ['organizationchart'], moduleId: PLATFORM.moduleName('components/organization/organizationchart/orgchartcomponent'), name: 'organizationchart',  title: 'OrganizationChart', settings: { auth: true }},
            {route: ['orgchartpage'], moduleId: PLATFORM.moduleName('components/organization/orgchartpage/chartpage'), name: 'orgchartpage',  title: 'OrgChartPage', settings: { auth: true }},
            {route: ['mycompoff'], moduleId: PLATFORM.moduleName('components/attendancetracking/compoff/mycompoffrequest/mycompoff'), name: 'mycompoffrequest',  title: 'My Comp Off Request', settings: { auth: true }},
            {route: ['myassetlist'], moduleId: PLATFORM.moduleName('components/assetmanagement/myassetcomponent/myassetcomponent'), name: 'myassetcomponent',  title: 'My Assets', settings: { auth: true }},
            {route: ['hierarchychart'], moduleId: PLATFORM.moduleName('components/hierarchychart/hierarchychartcomponent'), name: 'hierarchychart',  title: 'Hierarchy Chart', settings: { auth: true }},
            {route: ['ptoannouncement'], moduleId: PLATFORM.moduleName('components/organization/ptoannouncement/ptoannouncementcomponent'), name: 'ptoannouncement',  title: 'PTO Announcements', settings: { auth: true }},
            {route: ['employeesalaryrevision'], moduleId: PLATFORM.moduleName('components/employeesalaryrevision/employeesalaryrevisioncomponent'), name: 'employeesalaryrevision',  title: 'Employee Salary Revision', settings: { auth: true }},
            {route: ['MyKRADetails'], moduleId: PLATFORM.moduleName('components/performancereview/employeeperformacereview/performancereviewcomponent'), name: 'performancereview',  title: 'Performance Review', settings: { auth: true }},
            {route: ['MyKRADetails/managerview'], moduleId: PLATFORM.moduleName('components/performancereview/employeeperformacereview/managerview/performanceremanagerview'), name: 'performancereview',  title: 'Performance Review', settings: { auth: true }},
            {route: ['MyKRADetails/goalsandcommitments'], moduleId: PLATFORM.moduleName('components/performancereview/goalsandcommitments/goalsandcommitments'), name: 'goalsandcommitments',  title: 'Performance Review', settings: { auth: true }},
            {route: ['viewMyKRADetails'], moduleId: PLATFORM.moduleName('components/performancereview/employeeperformacereview/viewperformacereview/viewperformacereviewcomponent'), name: 'performancereview',  title: 'Performance Review', settings: { auth: true }},
            {route: ['KRAPerformanceReview/MyGoalSheet'], moduleId: PLATFORM.moduleName('components/performancereview/mykra/mygoalsandkrasheetcomponent'), name: 'KRAPerformanceReview/MyGoalSheet',  title: 'KRAPerformanceReview & MyGoalSheet',settings: { auth: true }},
            {route: ['resignation'], moduleId: PLATFORM.moduleName('components/resignation/resignationcomponent'), name: 'resignation',  title: 'Resignation', settings: { auth: true }},
            {route: ['timeoffadjustments'], moduleId: PLATFORM.moduleName('components/leavemanagement/timeoffadjustments/timeoffadjustmentscomponent'), name: 'timeoffadjustments',  title: 'Time off Adjustments', settings: { auth: true }},
            {route: ['employeetimeoffadjustment'], moduleId: PLATFORM.moduleName('components/leavemanagement/employeetimeoffadjustmentdetailes/employeetimeoffadjustmentcomponent'), name: 'employeetimeoffadjustment',  title: 'Employee Time Off Adjustment', settings: { auth: true }},
            {route: ['test'], moduleId: PLATFORM.moduleName('components/test/testlistscreencomponent'), name: 'test',  title: 'test', settings: { auth: true }},
            {route: ['leavecredits'], moduleId: PLATFORM.moduleName('components/leavemanagement/leavecredits/leavecreditscomponent'), name: 'leavecredits',  title: 'Leave Credits', settings: { auth: true }},
            {route: ['resignationform'], moduleId: PLATFORM.moduleName('components/resignationform/resignationformcomponent'), name: 'resignationform',  title: 'Resignation Form', settings: { auth: true }},
          //  {route: ['KRAPerformanceReview/PerformanceReviewInitiation'], moduleId: PLATFORM.moduleName('components/performancereview/performancereviewinitiation/performancereviewinitiationcomponent'), name: 'KRAPerformanceReview/PerformanceReviewInitiation',  title: 'Performance Review Initiation',settings: { auth: true }},
            {route: ['KRAPerformanceReview/employeeperformancelist'], moduleId: PLATFORM.moduleName('components/performancereview/employeeperformancelist/employeeperformancelist'), name: 'KRAPerformanceReview/employeeperformancelist',  title: 'Employee Performance list',settings: { auth: true }},
            {route: ['viewkraprsettings'], moduleId: PLATFORM.moduleName('components/leavemanagement/viewkraprsettings/viewkraprsettingscomponent'), name: 'viewkraprsettings',  title: 'View KRAPRSettings', settings: { auth: true }},
            {route: ['viewapplicabilityforkra'], moduleId: PLATFORM.moduleName('components/performancereview/viewapplicabilityforkra/viewapplicabilityforkracomponent'), name: 'viewapplicabilityforkra',  title: 'View Applicability KRA', settings: { auth: true }},
            {route: ['salaryrevision'], moduleId: PLATFORM.moduleName('components/salaryrevision/salaryrevisioncomponent'), name: 'salaryrevision',  title: 'Salary Revision', settings: { auth: true }},
            {route: ['promotionletter'], moduleId: PLATFORM.moduleName('components/salaryrevision/promotionletter/promotionlettercomponent'), name: 'promotionletter',  title: 'Promotion Letter', settings: { auth: true }},
            {route: ['employeesalary'], moduleId: PLATFORM.moduleName('components/salaryrevision/employeesalary/employeesalarycomponent'), name: 'employeesalary',  title: 'Employee Salary', settings: { auth: true }},
            {route: ['managerview'], moduleId: PLATFORM.moduleName('components/performancereview/managerview/managerviewcomponent'), name: 'managerview',  title: 'Managerview', settings: { auth: true }},
            {route: ['confirmationreview'], moduleId: PLATFORM.moduleName('components/confirmation/confirmationreview/confirmationreviewcomponent'), name: 'confirmationreview',  title: 'Confirmation Review', settings: { auth: true }},
            {route: ['myperiodicreview'], moduleId: PLATFORM.moduleName('components/confirmation/myperiodicreview/myperiodicreviewcomponent'), name: 'myperiodicreview',  title: 'Myperiodic Review', settings: { auth: true }},
            {route: ['periodicreview'], moduleId: PLATFORM.moduleName('components/confirmation/periodicreview/periodicreviewcomponent'), name: 'periodicreview',  title: 'Periodic Review', settings: { auth: true }},
            {route: ['peerreview'], moduleId: PLATFORM.moduleName('components/confirmation/peerreview/peerreviewcomponent'), name: 'peerreview',  title: 'Peer Review', settings: { auth: true }},
            {route: ['confirmationfeedbackform'], moduleId: PLATFORM.moduleName('components/confirmation/confirmationfeedbackform/confirmationfeedbackformcomponent'), name: 'confirmationfeedbackform',  title: 'Confirmation Feedback Form', settings: { auth: true }},
            {route: ['confirmationfeedbackformview'], moduleId: PLATFORM.moduleName('components/confirmation/confirmationfeedbackformview/confirmationfeedbackformviewcomponent'), name: 'confirmationfeedbackformview',  title: 'Confirmation Feedback Form View', settings: { auth: true }},
            {route: ['MyKRADetails/AssignKra'], moduleId: PLATFORM.moduleName('components/performancereview/employeeperformancelist/assignkra/performancereviewmanagerview'), name: 'AssignKra',  title: 'Assign Kra manger', settings: { auth: true }},
            {route: ['MyKRADetails/ViewAssignKra'], moduleId: PLATFORM.moduleName('components/performancereview/employeeperformancelist/viewassignkra/viewperformancereviewmanagerview'), name: 'viewAssignKra',  title: 'view Assign Kra manger', settings: { auth: true }},

        ]);

        this.router = router;
    }
    /**
     * This function is used to weather to hide or show the header and sidebar.
     * @param newValue
     */
    updatePropertyValue(newValue){
        this.login=newValue
    }

}
